import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import ShopifyClient from 'shopify-buy'
import Cookies from 'js-cookie'

const client = ShopifyClient.buildClient({
	storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN,
	domain: process.env.GATSBY_SHOPIFY_STORE
})

const tempChecklist = {
	'checklist': 'expecting-mums',
	'email': 'mail@jackfowler.work',
	'answers': [
		{
			'value': 'never',
			'question': {
				'text': 'I feel exhausted, even if I have had enough sleep.',
				'redFlag': null
			},
			'section': 'Body & behaviour',
			'index': 0
		},
		{
			'value': 'often',
			'question': {
				'text': 'I can’t sleep at night, even if my partner is sleeping.',
				'redFlag': true
			},
			'section': 'Body & behaviour',
			'index': 1
		},
		{
			'value': 'sometimes',
			'question': {
				'text': 'I have little or no appetite.',
				'redFlag': null
			},
			'section': 'Body & behaviour',
			'index': 2
		},
		{
			'value': 'constantly',
			'question': {
				'text': 'I crave/eat comfort food to help me feel better emotionally.',
				'redFlag': null
			},
			'section': 'Body & behaviour',
			'index': 3
		},
		{
			'value': 'often',
			'question': {
				'text': 'I crave/use alcohol or other drugs to help me feel better emotionally.',
				'redFlag': null
			},
			'section': 'Body & behaviour',
			'index': 4
		},
		{
			'value': 'sometimes',
			'question': {
				'text': 'I do things that are potentially risky to my wellbeing .  ',
				'redFlag': null
			},
			'section': 'Body & behaviour',
			'index': 5
		},
		{
			'value': 'never',
			'question': {
				'text': 'I have physical symptoms that could be worry or anxiety e.g. jitters or “butterflies" in my stomach, nausea or tension headaches.',
				'redFlag': null
			},
			'section': 'Body & behaviour',
			'index': 6
		},
		{
			'value': 'never',
			'question': {
				'text': 'I have had symptoms that could be a panic attack (racing heart, palpitations, shortness of breath, shaking, feeling ‘detached’ from my surroundings)',
				'redFlag': null
			},
			'section': 'Body & behaviour',
			'index': 7
		},
		{
			'value': 'never',
			'question': {
				'text': 'I am throwing myself into work or study to avoid things at home.     ',
				'redFlag': null
			},
			'section': 'Body & behaviour',
			'index': 8
		},
		{
			'value': 'never',
			'question': {
				'text': 'I am cleaning the house a lot more than usual.',
				'redFlag': null
			},
			'section': 'Body & behaviour',
			'index': 9
		},
		{
			'value': 'never',
			'question': {
				'text': 'I need to check things over and over e.g. when leaving the house.',
				'redFlag': null
			},
			'section': 'Body & behaviour',
			'index': 10
		},
		{
			'value': 'sometimes',
			'question': {
				'text': 'I am experiencing sudden changes in mood.    ',
				'redFlag': null
			},
			'section': 'Thoughts and feelings',
			'index': 11
		},
		{
			'value': 'never',
			'question': {
				'text': 'I am afraid to leave the house and/or to be with other people.',
				'redFlag': null
			},
			'section': 'Thoughts and feelings',
			'index': 12
		},
		{
			'value': 'never',
			'question': {
				'text': 'I am afraid to be alone.',
				'redFlag': null
			},
			'section': 'Thoughts and feelings',
			'index': 13
		},
		{
			'value': 'often',
			'question': {
				'text': 'I am more angry, irritable or intolerant with those around me.',
				'redFlag': null
			},
			'section': 'Thoughts and feelings',
			'index': 14
		},
		{
			'value': 'constantly',
			'question': {
				'text': 'I feel worried, nervous, ‘on edge’ or panicky.',
				'redFlag': null
			},
			'section': 'Thoughts and feelings',
			'index': 15
		},
		{
			'value': 'never',
			'question': {
				'text': 'I am often worried e.g. about my partner’s or baby’s health, the birth or the future.',
				'redFlag': null
			},
			'section': 'Thoughts and feelings',
			'index': 16
		},
		{
			'value': 'never',
			'question': {
				'text': 'I feel disconnected from my partner’s pregnancy and our baby.',
				'redFlag': null
			},
			'section': 'Thoughts and feelings',
			'index': 17
		},
		{
			'value': 'never',
			'question': {
				'text': 'I feel sad and low and/or often find myself crying.',
				'redFlag': null
			},
			'section': 'Thoughts and feelings',
			'index': 18
		},
		{
			'value': 'never',
			'question': {
				'text': 'I find it very hard to focus, concentrate, remember or make decisions.',
				'redFlag': null
			},
			'section': 'Thoughts and feelings',
			'index': 19
		},
		{
			'value': 'never',
			'question': {
				'text': 'I have no confidence and feel that I will be a terrible parent.',
				'redFlag': null
			},
			'section': 'Thoughts and feelings',
			'index': 20
		},
		{
			'value': 'never',
			'question': {
				'text': 'I am not enjoying simple things that used to give me joy.',
				'redFlag': null
			},
			'section': 'Thoughts and feelings',
			'index': 21
		},
		{
			'value': 'never',
			'question': {
				'text': 'I have had thoughts of harming my unborn child.',
				'redFlag': true
			},
			'section': 'Thoughts and feelings',
			'index': 22
		},
		{
			'value': 'never',
			'question': {
				'text': 'Since my partner became pregnant, I have had thoughts of harming myself and/or have harmed myself.',
				'redFlag': true
			},
			'section': 'Thoughts and feelings',
			'index': 23
		},
		{
			'value': 'never',
			'question': {
				'text': 'Since my partner became pregnant, I have had thoughts about running away, dying or ending my life.',
				'redFlag': true
			},
			'section': 'Thoughts and feelings',
			'index': 24
		},
		{
			'value': 'never',
			'question': {
				'text': 'I feel upset with/am having conflict with my partner more than usual.',
				'redFlag': null
			},
			'section': 'Relationships',
			'index': 25
		},
		{
			'value': 'sometimes',
			'question': {
				'text': 'I feel more withdrawn and distant from my partner.',
				'redFlag': null
			},
			'section': 'Relationships',
			'index': 26
		},
		{
			'value': 'often',
			'question': {
				'text': 'I feel more withdrawn and distant from friends and family.',
				'redFlag': null
			},
			'section': 'Relationships',
			'index': 27
		},
		{
			'value': 'never',
			'question': {
				'text': 'I feel upset about my childhood, or with my parents or other family members.',
				'redFlag': null
			},
			'section': 'Relationships',
			'index': 28
		},
		{
			'value': 'never',
			'question': {
				'text': 'I feel unsafe at home or in my relationship with my partner or ex-partner (this could be physical and/or emotional)',
				'redFlag': null
			},
			'section': 'Relationships',
			'index': 29
		}
	],
	'response': 'a',
	'title': 'Expecting Mums',
	'sections': [
		{
			'title': 'Body & behaviour',
			'questions': [
				{
					'text': 'I feel exhausted, even if I have had enough sleep.',
					'redFlag': null
				},
				{
					'text': 'I can’t sleep at night, even if my partner is sleeping.',
					'redFlag': true
				},
				{
					'text': 'I have little or no appetite.',
					'redFlag': null
				},
				{
					'text': 'I crave/eat comfort food to help me feel better emotionally.',
					'redFlag': null
				},
				{
					'text': 'I crave/use alcohol or other drugs to help me feel better emotionally.',
					'redFlag': null
				},
				{
					'text': 'I do things that are potentially risky to my wellbeing .  ',
					'redFlag': null
				},
				{
					'text': 'I have physical symptoms that could be worry or anxiety e.g. jitters or “butterflies" in my stomach, nausea or tension headaches.',
					'redFlag': null
				},
				{
					'text': 'I have had symptoms that could be a panic attack (racing heart, palpitations, shortness of breath, shaking, feeling ‘detached’ from my surroundings)',
					'redFlag': null
				},
				{
					'text': 'I am throwing myself into work or study to avoid things at home.     ',
					'redFlag': null
				},
				{
					'text': 'I am cleaning the house a lot more than usual.',
					'redFlag': null
				},
				{
					'text': 'I need to check things over and over e.g. when leaving the house.',
					'redFlag': null
				}
			],
			'background': {
				'value': '#E1F9F7'
			}
		},
		{
			'title': 'Thoughts and feelings',
			'questions': [
				{
					'text': 'I am experiencing sudden changes in mood.    ',
					'redFlag': null
				},
				{
					'text': 'I am afraid to leave the house and/or to be with other people.',
					'redFlag': null
				},
				{
					'text': 'I am afraid to be alone.',
					'redFlag': null
				},
				{
					'text': 'I am more angry, irritable or intolerant with those around me.',
					'redFlag': null
				},
				{
					'text': 'I feel worried, nervous, ‘on edge’ or panicky.',
					'redFlag': null
				},
				{
					'text': 'I am often worried e.g. about my partner’s or baby’s health, the birth or the future.',
					'redFlag': null
				},
				{
					'text': 'I feel disconnected from my partner’s pregnancy and our baby.',
					'redFlag': null
				},
				{
					'text': 'I feel sad and low and/or often find myself crying.',
					'redFlag': null
				},
				{
					'text': 'I find it very hard to focus, concentrate, remember or make decisions.',
					'redFlag': null
				},
				{
					'text': 'I have no confidence and feel that I will be a terrible parent.',
					'redFlag': null
				},
				{
					'text': 'I am not enjoying simple things that used to give me joy.',
					'redFlag': null
				},
				{
					'text': 'I have had thoughts of harming my unborn child.',
					'redFlag': true
				},
				{
					'text': 'Since my partner became pregnant, I have had thoughts of harming myself and/or have harmed myself.',
					'redFlag': true
				},
				{
					'text': 'Since my partner became pregnant, I have had thoughts about running away, dying or ending my life.',
					'redFlag': true
				}
			],
			'background': {
				'value': '#FDC787'
			}
		},
		{
			'title': 'Relationships',
			'questions': [
				{
					'text': 'I feel upset with/am having conflict with my partner more than usual.',
					'redFlag': null
				},
				{
					'text': 'I feel more withdrawn and distant from my partner.',
					'redFlag': null
				},
				{
					'text': 'I feel more withdrawn and distant from friends and family.',
					'redFlag': null
				},
				{
					'text': 'I feel upset about my childhood, or with my parents or other family members.',
					'redFlag': null
				},
				{
					'text': 'I feel unsafe at home or in my relationship with my partner or ex-partner (this could be physical and/or emotional)',
					'redFlag': null
				}
			],
			'background': {
				'value': '#C3EDEA'
			}
		}
	]
}

const initialSiteState = {
	pageTitle: null,
	cart: [],
	cartOpen: false,
	checklistData: {},
	popUp: false
}

export const SiteContext = React.createContext({
	siteState: initialSiteState,
	setSiteState: undefined,
})

export const SiteStore = ({ children }) => {
	const [siteState, setSiteState] = useState(initialSiteState)
	const [init, setInit] = useState(false)

	useEffect(() => {
		if(init){
			localStorage.setItem('DOT_CART', JSON.stringify(siteState.cart), { expires: 5 })
		}
	}, [siteState, init])

	const loadCartFromCookie = () => {
		const cartCookie = localStorage.getItem('DOT_CART')
		if(cartCookie){
			setSiteState(prevState => ({
				...prevState,
				cart: JSON.parse(cartCookie)
			}))
		}
		setInit(true)
	}

	const destroyCart = () => {
		Cookies.remove('DOT_CHECKOUT_ID')
		localStorage.removeItem('DOT_CART')
		setInit(true)
	}

	useEffect(() => {
		const id = Cookies.get('DOT_CHECKOUT_ID')
		if(id){
			client.checkout.fetch(id).then(checkout => {
				if(checkout.completedAt){
					destroyCart()
				} else {
					loadCartFromCookie()
				}
			})
		} else {
			loadCartFromCookie()
		}
	}, [])

	return(
		<SiteContext.Provider value={{
			siteState: siteState,
			setSiteState: setSiteState
		}}>
			{children}
		</SiteContext.Provider>
	)
}

SiteStore.propTypes = {
	children: PropTypes.node,
}

// hook to access siteState globally
export const useSiteState = () => {
	const { siteState, setSiteState } = useContext(SiteContext)
	return [siteState, setSiteState]
}

// Cart functions
export const useCart = () => {

	const {siteState, setSiteState} = useContext(SiteContext)

	const addItem = item => {
		let itemIndex = siteState.cart.findIndex(c => c.id === item.id)
		if(itemIndex === -1){
			setSiteState(prevState => ({
				...prevState,
				cart:[
					...prevState.cart,
					item
				]
			}))
		}
		// no extra quantity required
		// else if(!siteState.cart[itemIndex].maxQty || item.qty + siteState.cart[itemIndex].qty <= siteState.cart[itemIndex].maxQty){
		// 	let newCart = siteState.cart
		// 	newCart[itemIndex].qty += item.qty
		// 	setSiteState(prevState => ({
		// 		...prevState,
		// 		cart: newCart
		// 	}))
		// }
	}

	const updateQty = (variantId, qty) => {
		let itemIndex = siteState.cart.findIndex(c => c.content?.shopify?.variantId === variantId)
		if(itemIndex === -1){
			return null
		}
		if(siteState.cart[itemIndex].maxQty && qty > siteState.cart[itemIndex].maxQty){
			return null
		}
		let newCart = siteState.cart
		newCart[itemIndex].qty = qty
		setSiteState(prevState => ({
			...prevState,
			cart: newCart
		}))
	}

	const removeItem = variantId => {
		let itemIndex = siteState.cart.findIndex(c => c.id === variantId)
		if(itemIndex === -1){
			return null
		}
		let newCart = siteState.cart
		newCart.splice(itemIndex, 1)
		setSiteState(prevState => ({
			...prevState,
			cart: newCart
		}))
	}

	const cartCount = () => {
		const reducer = (accumulator, currentValue) => accumulator + currentValue.qty
		return [...siteState.cart].reduce(reducer, 0)
	}

	const cartTotal = () => {
		const reducer = (accumulator, currentValue) => accumulator + currentValue.price * currentValue.qty
		return [...siteState.cart].reduce(reducer, 0)
	}

	const openCart = () => {
		setSiteState(prevState => ({
			...prevState,
			cartOpen: true
		}))
	}

	const closeCart = () => {
		setSiteState(prevState => ({
			...prevState,
			cartOpen: false
		}))
	}

	return {
		cart: siteState.cart,
		cartCount: cartCount,
		cartTotal: cartTotal,
		addItem: addItem,
		updateQty: updateQty,
		removeItem: removeItem,
		openCart: openCart,
		closeCart: closeCart,
	}
}