import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import SanityLink from '~components/SanityLink'
import {Rarr, Uarr, Cart, FillTick, FillCross, BdrCross, ChevronDown, Search} from '~components/Svg'
import { Link } from 'gatsby'

const Button = ({ className, children, link, onClick, bgColor, icon, outline, small, flex, underline, large, flipped, href, checklist, internalLink}) => {

	const renderIcon = ()=> {
		if(icon === 'Rarr'){
			return <RightArrow/>
		}	
		if (icon === 'Uarr') {
			return <UpArrow/>
		}
		if (icon === 'Cart') {
			return <CartIcon/>
		}
		if (icon === 'Tick') {
			return <Tick/>
		}
		if (icon === 'Cross') {
			return <Cross/>
		}
		if (icon === 'BdrCross') {
			return <CircleCross/>
		}
		if (icon === 'ChevronDown') {
			return <ChevDown css={css`
				transition: transform 0.3s;
				transform: ${flipped ? 'rotate(180deg)' : 'none'};
			`}/>
		}
		if (icon === 'Search') {
			return <SearchIcon/>
		}
		else {
			return null
		}		
	}

	const buttonWidth = () => {
		if (small) {
			return '126px'
		} else if (large) {
			return '350px'
		} else if (flex){
			return '0px'
		} else {
			return '220px'
		}
	}

	const buttonWidthMob = () => {
		if (small) {
			return '126px'
		} else if (large) {
			return '100%'
		} else if (flex){
			return '0px'
		} else {
			return '100%'
		}
	}

	const textColor = () => {
		if (bgColor === 'teal60') {
			return 'var(--white)'
		} else if (bgColor === 'bteal60') {
			return 'var(--dteal)'
		} else if (bgColor?.length > 0 && bgColor != 'white'){
			return 'var(--white)'
		} else {
			return 'var(--dteal)'
		}
	}

	const hoverColor = () => {
		if (outline) {
			return 'var(--beige20)'
		} else if (bgColor === 'white' || !bgColor){
			return 'var(--dteal)'
		} else {
			return 'var(--beige20)'
		}
	}

	const hoverBgColor = () => {
		if (bgColor === 'teal60') {
			return 'var(--teal80)'
		} else if (bgColor === 'dteal') {
			return 'var(--dteal80)'
		} else if (bgColor === 'bteal60') {
			return 'var(--dteal80)'
		} else if (bgColor === 'bteal') {
			return 'var(--bteal60)'
		} else if (bgColor === 'white') {
			return 'var(--beige)'
		} else if (outline) {
			return 'var(--dteal)'
		}
	}

	const buttonCss = css`
		display: inline-flex;
		align-items: center;
		justify-content: ${ icon?.length > 0 ? 'space-between' : 'center'};
		background-color: ${ bgColor?.length > 0 ? `var(--${bgColor})` : 'transparent'};
		border: ${ outline ? '3px solid' : 'none'};
		border-color: ${underline ? 'var(--bteal)' : 'var(--dteal)'};
		color: ${textColor()};
		min-height: ${flex ? 'auto' : '60px'};
		padding: ${flex ? '0' : '6px var(--xs)'};
		box-sizing: border-box;
		min-width: ${buttonWidth()};
		border-radius: 4px;
		cursor: pointer;
		transition: background-color 0.1s, color 0.1s;
		:hover{
			background-color: ${ bgColor?.length > 0 || outline ? hoverBgColor() : 'transparent'};
			color: ${hoverColor()}
		}
		${mobile}{
			min-width: ${buttonWidthMob()};
		}
	`
	const underlineCss = css`
		position: relative;
		padding-bottom: 0;
		transition: all 0.2s; 
		box-sizing: border-box;
		:hover {
			&:after {
				opacity: ${(flex && icon === 'Rarr') ? '1' : '0'}
			}
		}
		&:after {
			content: '';
			height: 2px;
			background-color: var(--bteal);
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			transform: translateY(4px);
			transition: all 0.2s; 
			opacity: ${underline ? '1' : '0'}
		}
	`
	if(href){
		return (<a 
			className={`${className} ${checklist ? 'bold' : 'h5'} button`}
			href={href}
			target='_blank'
			css={buttonCss} rel="noreferrer"
		>
			<Underline css={underlineCss}>{children}</Underline>{renderIcon()}
		</a>)
	}
	else if (link) {
		return (
			<Wrap 
				className={`${className} ${checklist ? 'bold' : 'h5'} button`}
				link={link}
				css={buttonCss}
			>
				<Underline css={underlineCss}>{children}</Underline>{renderIcon()}
			</Wrap>
		)
	} else if (internalLink) {
		return (
			<Link 
				className={`${className} ${checklist ? 'bold' : 'h5'} button`}
				to={internalLink}
				css={buttonCss}
			>
				<Underline css={underlineCss}>{children}</Underline>{renderIcon()}
			</Link>
		)
	}
	else if (onClick) {
		return (
			<ButtonWrap 
				className={`${className} ${checklist ? 'bold' : 'h5'} button`}
				onClick={onClick}
				css={buttonCss}
			>
				<Underline css={underlineCss}>{children}</Underline>{renderIcon()}
			</ButtonWrap>
		)
	} else {
		return (
			<NoButtonWrap 
			className={`${className} ${checklist ? 'bold' : 'h5'} button`}
				css={buttonCss}
			>
				<Underline css={underlineCss}>{children}</Underline>{renderIcon()}
			</NoButtonWrap>
		)
	}
}

const Wrap = styled(SanityLink)`
`
const ButtonWrap = styled.button`
`
const NoButtonWrap = styled.div`
	
`

const Underline = styled.div`
	display: inline-block;
`
const RightArrow = styled(Rarr)`
	width: 14px;
	margin-left: var(--xxs);
`
const UpArrow = styled(Uarr)`
	width: 11px;
	margin-left: var(--xxs);
`
const CartIcon = styled(Cart)`
	width: 25px;
	margin-left: var(--xxs);
`
const Tick = styled(FillTick)`
	width: 34px;
	margin-left: var(--xxs);
`
const Cross = styled(FillCross)`
	width: 34px;
	margin-left: var(--xxs);
`
const CircleCross = styled(BdrCross)`
	width: 34px;
	margin-left: var(--xxs);
`
const ChevDown = styled(ChevronDown)`
	width: 15px;
	margin-left: var(--xxs);
`

const SearchIcon = styled(Search)`
	width: 24px;
	margin-left: var(--xxs);
`

Button.propTypes = {
	className: PropTypes.string,
	children: PropTypes.string,
	link: PropTypes.string,
	onClick: PropTypes.func,
	bgColor: PropTypes.string,
	icon: PropTypes.string, 
	outline: PropTypes.bool,
	small: PropTypes.bool,
	flex: PropTypes.bool,
	underline: PropTypes.bool,
}

export default Button