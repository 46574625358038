import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        siteSocialImage: socialImage {
          asset {
            _id
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
				sendFeedbackTo
				popUpTitle
				popUpText: _rawPopUpText(resolveReferences:{ maxDepth: 4 })
				popUpButton{
					text
					link{
						...link
					}
				}
				showPopUp
      }
			sanityFooter {
				flags {
					...imageWithAlt
				}
				acknowledgementText: _rawAcknowledgementText(resolveReferences:{ maxDepth: 4})
				acknowledgementButton{
					text
					link{
						...link
					}
				}
				socialTitle
				socialText: _rawSocialText(resolveReferences:{ maxDepth: 4})
				signUpLink {
					text
					link {
						...link
					}
				}
				facebookUrl
				instagramUrl
				linkedInUrl
				footerMenu {
					title
					links {
						text
						link {
							...link
						}
					}
				}
				disclaimer: _rawDisclaimer(resolveReferences:{ maxDepth: 4})
				finePrintMenu{
					text
					link{
						...link
					}
				}
			}
    }
    `)

	return {...siteSettings?.sanitySiteSettings, ...siteSettings?.sanityFooter}
}

export default useSiteSettings
