import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Button from '~components/Button'
import { useSiteState } from '~context/siteContext'
import { useQueryParam, StringParam } from 'use-query-params'

const SearchBar = ({ className, pinned }) => {
	const [term, setTerm] = useQueryParam('q', StringParam)

	const handleSubmit = e => {
		e.preventDefault()
		let term = e.target[0].value
		navigate(`/search?q=${term}`)
	}

	const inputRef = useRef()

	useEffect(() => {
		if(term && inputRef.current){
			inputRef.current.value = term
		}
	}, [term])

	const [ siteState ] = useSiteState()
	

	return (
		siteState.searchOpen ?
			<Wrap className={className} onSubmit={handleSubmit} pinned={pinned}>
				<Input type="text" className="h4" ref={inputRef} autoFocus />
				<Button bgColor="white" type="submit" onClick={() => null}>Search</Button>
			</Wrap> : null
	)
}

const Input = styled.input`
	background: var(--vdteal);
	border: 0;
	color: inherit;
	padding: var(--xxs) var(--xs);
	display: block;
	width: 100%;
	max-width: 800px;
	${mobile}{
		box-sizing: border-box;
		margin-bottom: var(--s);
	}
`
const Wrap = styled.form`
	display: flex;
	justify-content: center;
	position: absolute;
	top: 100px;
	left: 0px;
	right: 0px;
	padding: var(--m);
	background: var(--dteal);
	color: var(--white);
	transition: opacity .3s;
	opacity: ${props => props.pinned ? 1 : 0};
	z-index: 79; 
	${tablet}{
		top: 80px;
	}
	${mobile}{
		display: block;
	}
`

SearchBar.propTypes = {
	className: PropTypes.string
}

export default SearchBar