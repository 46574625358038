import sectionsWithUrls from './sectionsWithUrls'

const resolveLink = (doc) => {
	if(!doc) return null
	const type = doc._type
	const slug = doc.slug?.current

	if(slug === 'home') return '/'

	if(type === 'page' && doc.section === 'smart-search'){
		return `/${doc.audience}/${slug}`
	}

	if(type === 'page' && doc.section === 'checklist-results'){
		return `/checklist-results/${doc.associatedChecklist?.slug?.current}/${doc.response}`
	}

	switch (type) {
	case 'product':
		return `/products/${doc.slug?.current}`
	case 'group':
		return `/products/${doc?.slug?.current}`
	case 'variant':
		return `/products/${doc.content?.shopify?.productSlug}/?v=${doc.content?.shopify?.variantId}`
	case 'article':
		return `/articles/${slug}`
	case 'story':
		return `/stories/${slug}`
	case 'volunteerStory':
		return `/volunteer-profiles/${slug}`
	case 'articleIndex':
		return '/articles'
	case 'storyIndex':
		return '/stories'
	case 'volunteerStoryIndex':
		return '/volunteer-profiles'
	case 'productIndex':
		return '/products/all-courses'
	case 'postIndex':
		return `/${slug}`
	case 'page':
		return `${sectionsWithUrls.includes(doc.section) ? '/' + doc.section : ''}/${slug}`
	case 'checklistDoc':
		return `/checklists/${doc?.slug?.current}`
	default:
		return `/${slug}`
	}

}

export default resolveLink
