import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import GlobalStyles, { maxMenu } from '~styles/global'
import Header from '~components/Header'
import Cart from '~components/Cart'
import Footer from '~components/Footer'
import smoothscroll from 'smoothscroll-polyfill'
import { mobile } from '~styles/global'
import Transition from '~components/Transition'
import { useSiteState, useCart } from '../context/siteContext'
import styled from '@emotion/styled'
import { useLocation } from '@reach/router'
import '~styles/static.css'
import Button from '~components/Button'
import PopUp from '~components/PopUp'
import Hotjar from '@hotjar/browser'

const siteId = 3659613
const hotjarVersion = 6

const Layout = ({ children }) => {

	const [siteState, setSiteState] = useSiteState()
	const { closeCart } = useCart()
	const location = useLocation()
	Hotjar.init(siteId, hotjarVersion)
  
	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	// Close the cart whenever the route changes
	useEffect(() => {
		closeCart()
		// eslint-disable-next-line
  }, [location])

	return (
		<>
			<GlobalStyles />
			{siteState.popUp && 
				<Global styles={css`
					html, body {
						overflow: hidden;
					}
				`}/>
			}
			<Header />
			{/* <Cart open={siteState.cartOpen} /> */}
			<Main>
				<Transition elementKey={location.pathname} duration={0}>
					<div css={css`
            min-height: ${location.pathname === '/chat' ? 0 : 'calc(100vh)'};
            display: flex;
            flex-direction: column;
            ${mobile}{
							min-height: ${location.pathname === '/chat' ? 0 : 'calc(100vh)'};
            }
          `}>
						{children}
						{console.log(location.pathname)}
						{location.pathname !== '/chat' && <Footer css={css`margin-top: auto;`}/>}
					</div>
				</Transition>
			</Main>
			{!location.pathname.includes('/checklists') &&
				<QuickExit 
					rel='noreferrer' 
					link={{
						linkType: 'external', 
						url: 'https://google.com',
					}}
					bgColor='orange'
					icon='BdrCross'
					className='cap'
				>
					Quick Exit Site
				</QuickExit>
			}
			<PopUp />	
			<Overlay 
				onClick={() => setSiteState(prevState => ({
					...prevState,
					popUp: false,
				}))}
				show={siteState.popUp}
			/>
		</>
	)
}

const Main = styled.main`
	margin-top: 100px;
	${maxMenu}{
		margin-top: 80px;
	}
`
const Overlay = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	background-color: var(--white);
	pointer-events: ${props => props.show ? 'all' : 'none'};
	opacity: ${props => props.show ? '0.5' : '0'};
	z-index: 111;
	transition: opacity 0.3s;
`
const QuickExit = styled(Button)`
  position: fixed;
  right: 0;
	z-index: 112;
  bottom: var(--xxxxl);
	color: inherit;
	transform: rotate(90deg);
	transform-origin: 76% 76%;
  min-width: 190px;
	:hover {
		color: inherit;
	}
	${mobile}{
		min-width: 190px;
	}
`

Layout.propTypes = {
	children: PropTypes.node,
}

export default Layout
