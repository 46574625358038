import styled from '@emotion/styled'
import { mobile, tablet } from '~styles/global'

const ModuleStyles = styled.div`
	p > a {
		position: relative;
		:after {
			position: absolute;
			bottom: -1px;
			left: 0;
			right: 0;
			background-color: var(--bteal);
			height: 2px;
			content: '';
		}
	}
	h3{
		max-width: 450px;
		margin-bottom: var(--m);
		:not(:first-child){
			padding-top: var(--s);
		}
	}
	h4{
		margin-bottom: var(--xs);
		:not(:first-child){
			padding-top: var(--xs);
		}
	}
	h5{
		margin-bottom: var(--xxs);
		:not(:first-child){
			padding-top: var(--xxs);
		}
	}
	p{
		margin-bottom: var(--s);
		${mobile}{
		}
	}
	h3, h4, p {
		min-height: 0.5rem;
	}
`

export default ModuleStyles