import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useSiteState } from '~context/siteContext'
import Cookies from 'js-cookie'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from '~components/RichText'
import { BdrCross } from '~components/Svg'
import Button from '~components/Button'
import { navigate } from 'gatsby'
import resolveLink from '~utils/resolveLink'

const PopUp = ({ className }) => {

	const [siteState, setSiteState] = useSiteState()

	const { showPopUp, popUpTitle, popUpText, popUpButton } = useSiteSettings()

	useEffect(() => {
		if(!Cookies.get('popUpClosed') && showPopUp){
			setTimeout(() => {
				setSiteState(prevState => ({
					...prevState,
					popUp: true
				}))
			}, 3000)
		}
	}, [])

	const close = () => {
		Cookies.set('popUpClosed', true, { expires: 14 })
		setSiteState(prevState => ({
			...prevState,
			popUp: false
		}))
	}

	const closeAndNavigate = () => {
		setSiteState(prevState => ({
			...prevState,
			popUp: false
		}))
		navigate(resolveLink(popUpButton?.link?.document))
	}

	return (
		<Wrap className={className} show={siteState.popUp}>
			<Title>{popUpTitle}</Title>
			<Text>
				<RichText content={popUpText}/>
			</Text>
			<StyledButton onClick={() => closeAndNavigate()} outline>
				{popUpButton?.text}
			</StyledButton>
			<Close className='cap' onClick={() => close()}>Go To Website <CloseIcon/></Close>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	width: 100%;
	max-width: 816px;
	background-color: var(--bteal60);
	padding: var(--s);
	transform: translate(-50%, -50%);
	z-index: 112;
	opacity: ${props => props.show ? '1' : '0'};
	pointer-events: ${props => props.show ? 'all' : 'none'};
	transition: opacity 0.4s;
	text-align: center;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 940px){
		max-width: calc(100% - 60px);
		padding: var(--s) var(--m);
	}
`
const Title = styled.h2`
	margin-bottom: var(--xl);
	${mobile}{
		margin-bottom: var(--s);
	}
`
const Text = styled.div`
	margin-bottom: var(--m);
	max-width: 590px;
	${mobile}{
		margin-bottom: var(--s);
	}
`
const StyledButton = styled(Button)`
	margin-bottom: var(--m);
	${mobile}{
		margin-bottom: var(--s);
	}
`
const Close = styled.button`
	display: inline-flex;
	align-items: center;
	margin-bottom: var(--s);
	${mobile}{
		margin-bottom: 0;
	}
`
const CloseIcon = styled(BdrCross)`
	width: 34px;
	margin-left: var(--xs);
`

PopUp.propTypes = {
	className: PropTypes.string
}

export default PopUp