import React from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Bullet } from '~components/Svg'
import isArabic from '~utils/isArabic'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 1025px)',
	maxMenu: '@media(max-width: 1216px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp
export const maxMenu = breakpoints.maxMenu


const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`
	html {
		scroll-padding: 100px 0 0 0;
		${maxMenu}{
			scroll-padding: 80px 0 0 0;			
		}
	}
	
  body {
    font-family: 'Raleway', sans-serif;
		font-size: 20px;
		line-height: 26px;
		font-variation-settings: 'wght' 500;
		color: var(--dteal);
  }

	body, button {
		font-feature-settings: 
		'ss06', 
		'ss09', 
		'lnum', 
		'tnum',
		'onum' 0,
		'pnum' 0,
		'dlig' 0,
		'liga' 0,
		'clig' 0
		;
	}

  :root{
    --white: #ffffff;
    --black: #000000;
		--vdteal: #062224;
		--dteal: #11383A;
		--dteal80: #37595A;
		--dteal60: #617C7D;
		--teal: #236D6D;
		--teal80: #328282;
		--teal60: #679997;
		--bteal: #49C5B1;
		--bteal60: #86DECF;
		--bteal40: #C3EDEA;
		--bteal20: #E1F9F7;
		--orange: #FF6A39;
		--orange60: #FFA688;
		--peach: #FDC787;
		--peach80: #FFD29D;
		--peach60: #FFE4C9;
		--peach40: #F3E5D8;
		--peach20: #F3E5D8;
		--beige: #EDE8E4;
		--beige60: #F3F1F0;
		--beige20: #FBFAFA;
		--dbeige: #D7D2CE;
		--xdbeige: #C1C0BE;
		--light-grey: #DEDEDE;
		--grey: #B4B4B4;
    --xxxs: 5px;
    --xxs: 10px;
		--xs: 16px;
    --s: 24px;
    --m: 36px;
    --l: 48px;
    --xl: 60px;
		--xxl: 74px;
    --xxxl: 84px;
		--xxxxl: 100px;
  }

  ${tablet}{
    :root{
      /* --xxs: 3px;
      --xs: 7px;
      --s: 15px;
      --m: 20px;
      --l: 30px;
      --xl: 35px;
      --xxl: 40px;
      --xxxl: 60px;
      --xxxxl: 120px; */
    }
  }

  p{
    margin-bottom: 1.4em;
    &:last-child{
      margin-bottom: 0;
    }
  }

	h1,
	.h1,
  h2,
	.h2,
  h3,
	.h3,
  h4,
	.h4,
  h5,
	.h5, 
	.cap,{
    font-family: 'Moderat Bold', Arial, sans-serif;
		text-transform: uppercase;
		font-feature-settings: 
		'ss06' 0, 
		'ss09' 0,
		'zero' 0;
		font-variant-numeric: lining-nums;
		:lang(pa){
			color: red;
		}
  }

  h1,
  .h1 {
    font-size: 88px;
    line-height: 88px;
  }

	h2,
  .h2 {
    font-size: 72px;
    line-height: 72px;
		letter-spacing: -0.01em;
    ${tablet} {
      font-size: 54px;
      line-height: 54px;
    }
  }

	h3,
  .h3 {
		font-size: 46px;
    line-height: 50.6px;
		letter-spacing: 0.01em;
    ${tablet} {
      font-size: 34px;
      line-height: 37.4px;
    }
  }

	h4,
  .h4 {
    font-size: 32px;
    line-height: 36.8px;
		letter-spacing: 0.01em;
  }

	h5,
  .h5 {
    font-size: 20px;
    line-height: 26px;
		letter-spacing: 0.02em;
  }

	h6,
  .h6 {
		font-family: 'Raleway';
    font-size: 28px;
    line-height: 1.15;
		font-variation-settings: 'wght' 600;
		${tablet} {
      font-size: 24px;
    }
  }

	.para {
    font-size: 28px;
    line-height: 32.2px;
		letter-spacing: 0.02em;
		font-variation-settings: 'wght' 600;
	}

	.sub {
		font-family: 'Raleway';
		font-size: 20px;
		line-height: 26px;
		font-variation-settings: 'wght' 600;
	}

	.b2 {
    font-size: 14px;
    line-height: 16.8px;
		letter-spacing: 0.03em;
	}

	.cap {
		font-size: 14px;
    line-height: 15.4px;
		letter-spacing: 0.05em;
	}
	ol {
		padding-left: 20px;
	}
	.bold{
		font-weight: 700;
		font-variation-settings: 'wght' 700;
	}
`

export const Eyebrow = ({ children, className }) => {
	const rtl = isArabic(children)
	return (
		<EyebrowWrap className={className}>
			<div dir={rtl ? 'rtl' : 'ltr'}>
				{children}
			</div>
		</EyebrowWrap>
	)
}

export const EyebrowWrap = styled.h5`
	margin-bottom: var(--s);
`


export const ULI = styled.li`
	display: grid;
	grid-template-columns: 11px 1fr;
	grid-column-gap: 22px;
	margin-bottom: var(--m);
	${mobile}{
		margin-bottom: var(--s);
	}
`
export const OLI = styled.li`
	padding-left: 13px;
	margin-bottom: var(--m);
	${mobile}{
		margin-bottom: var(--s);
	}
`
export const BulletPoint = styled(Bullet)`
	margin-top: 7px;
	max-width: 11px;
`
const reset = css`
  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
  }

	textarea {
		font-family: inherit;
		border: 2px solid transparent;
		width: 100%;
		box-sizing: border-box;
		padding: var(--xs);
		resize: none;
		background: var(--white);
		font-size: inherit;
		color: inherit;
		line-height: inherit;
		&:focus{
			border: 2px solid var(--bteal)
		}
		&::placeholder{
			color: inherit;
		}
	}

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
		color: inherit;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 500px white inset !important;
    box-shadow: 0 0 0 500px white inset !important;
  }

	input:focus, 
	textarea:focus, 
	select:focus{
		outline: none;
	}
	
	input {
		background-image: none !important;
	}

	#__lpform_react-select-2-input_icon{
		display: none;
	}

	input {
  border-radius: 0;
	}

	input[type="search"] {
		-webkit-appearance: none;
	}
`

export default GlobalStyles
